@use 'variables' as *;

.header {
	position: relative;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: var(--primary-white);
	overflow: hidden;
	h1 {
		font-size: 1.8rem;
		margin: 1rem;
		z-index: 2;
		color: rgb(218, 218, 218);
	}
	&__content {
		z-index: 2;
		&--logo {
			width: 200px;
			border-radius: 50%;
		}
	}
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to top, rgb(30, 30, 30) 0%, rgba(255, 255, 255, 0) 100%);
	}
}

@media screen and (min-width: 992px) {
	.header {
		h1 {
			font-size: 2.5rem;
		}
		&__content {
			&--logo {
				width: 300px;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
}
