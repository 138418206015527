@use 'variables' as *;

body {
	font-family: 'Nunito', sans-serif;
	position: relative;
	background-color: var(--primary-dark);
	overflow-x: hidden;
}
.particles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

body {
	--sb-track-color: #1e1e1e;
	--sb-thumb-color: #8a2be2;
	--sb-size: 10px;
}

body::-webkit-scrollbar {
	width: var(--sb-size);
}

body::-webkit-scrollbar-track {
	background: var(--sb-track-color);
	border-radius: 3px;
}

body::-webkit-scrollbar-thumb {
	background: var(--sb-thumb-color);
	border-radius: 3px;
}

@supports not selector(::-webkit-scrollbar) {
	body {
		scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
	}
}
